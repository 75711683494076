import React, { useEffect, useRef } from "react";
import "./Video.css";
import { Link, useLoaderData, useParams } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import backgroundMusic from '../../assets/ringtone.mp3';
const Video = () => {




  const params = useParams();
  const { user } = useLoaderData();
  localStorage.setItem("url", `/live/chat/${params.id}`);
  let type;
  if (isBrowser) {
    type = "desktop";
  } else {
    type = "mobile";
  }
  useEffect(() => {
    const bookings = {
      type: type,
    };

    fetch(`https://jahidserver.vercel.app/updateclick/${params.id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then()
      .then();
  }, [type, params.id]);



  return (
    <div className="video">

      <div className="video-card text-center">
        <img src="/datas/images/eros-logo.jpg" alt="" className="img_mm_01" />
        <p>Incoimng Call...</p>
        <div className="btn-holder">
          <Link className="ree_01" to={`/live/chat/login/${params.id}`}>
            <img src="/datas/images/incoming-call.svg" alt="" />
          </Link>
          <Link className="ree_02" to={`/live/chat/login/${params.id}`}>
            <img src="/datas/images/outgoing-call.svg" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Video;
